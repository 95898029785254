import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import ServicesPage from "../components/ServicesPage"

const IndexPage = () => {
  const {
    contentfulServicesPage: {
      title,
      seo: { title: seoTitle = "", description = "" },
    },
  } = useStaticQuery(
    graphql`
      query ServicePage {
        contentfulServicesPage(slug: { eq: "services" }) {
          title
          slug
          seo {
            language
            title
            description
          }
          preFooter {
            id
            resourceCopy {
              raw
            }
            resourceTitle
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Seo title={seoTitle || title} description={description} />
      <ServicesPage />
    </Layout>
  )
}

export default IndexPage
