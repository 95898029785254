import React from "react"
import PropTypes from "prop-types"
import * as styles from "./styles.module.less"

const Cta = ({ text, link, type, children, ...rest }) => {
  let cls

  switch (type) {
    case "bordered":
      cls = styles.ctaBordered
      break
    case "white":
      cls = styles.ctaWhite
      break
    case "underline":
      cls = styles.ctaUnderline
      break
    default:
      cls = styles.cta
      break
  }

  return (
    <a className={cls} href={link} {...rest}>
      {text || children}
    </a>
  )
}

Cta.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(["bordered", "white"]),
}

export default Cta
