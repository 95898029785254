import React from "react"
import RichTextToReact from "rich-text-to-react"
import { useStaticQuery, graphql } from "gatsby"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const ConvenientCare = () => {
  const {
    contentfulServicesPage: {
      headline,
      body: { raw: document = "" },
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulServicesPage(slug: { eq: "services" }) {
          headline
          body {
            raw
          }
        }
      }
    `
  )
  return (
    <section className={styles.convenientCare}>
      <div className={styles.container}>
        <h1>{headline}</h1>
        <div className={styles.body}>
          <RichTextToReact
            document={JSON.parse(document)}
            options={renderOptions}
          />
        </div>
      </div>
    </section>
  )
}

export default ConvenientCare
