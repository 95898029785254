import React from "react"
import RichTextToReact from "rich-text-to-react"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "../Hero"
import ConvenientCare from "./components/ConvenientCare"
import InjuryCare from "./components/InjuryCare"
import OccupationalHealth from "./components/OccupationalHealth"
import Covid from "./components/Covid"
import PreFooter from "../PreFooter"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const ServicesPage = () => {
  const {
    contentfulServicesPage: {
      hero: {
        headline,
        heroImage: {
          desktop: { src: image },
        },
      },
      preFooter,
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulServicesPage(slug: { eq: "services" }) {
          hero {
            headline
            eyebrow
            heroImage {
              description
              desktop: resize(width: 1000) {
                src
              }
              mobile: resize(width: 500) {
                src
              }
            }
          }
          preFooter {
            id
            resourceCopy {
              raw
            }
            resourceTitle
          }
        }
      }
    `
  )

  const resources = preFooter.map(
    ({ id, resourceTitle: title, resourceCopy: { raw = "" } }) => {
      return {
        id,
        title,
        body: (
          <RichTextToReact document={JSON.parse(raw)} options={renderOptions} />
        ),
      }
    }
  )

  return (
    <div>
      <Hero headline={headline} image={image} />
      <ConvenientCare />
      <div className={styles.flexContainer}>
        <InjuryCare />
        <OccupationalHealth />
      </div>
      {/* <Covid /> */}
      <PreFooter resources={resources} blue />
    </div>
  )
}

export default ServicesPage
