// extracted by mini-css-extract-plugin
export var body = "styles-module--body--dxNgQ";
export var box = "styles-module--box--U1bdx";
export var btnFlex = "styles-module--btnFlex--NDY5q";
export var childDetail = "styles-module--childDetail--+p7Pi";
export var childPadding = "styles-module--childPadding--L2DvW";
export var childSummary = "styles-module--childSummary--u0wPw";
export var label = "styles-module--label--4-Rwm";
export var nestedLabel = "styles-module--nestedLabel--xNQd3";
export var occupationalHealth = "styles-module--occupationalHealth--kZoux";
export var parentDetail = "styles-module--parentDetail--AoFgG";
export var parentSummary = "styles-module--parentSummary--QtHSg";
export var src = "styles-module--src--biLFI";
export var summaryContent = "styles-module--summaryContent--TPVPK";