import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import RichTextToReact from "rich-text-to-react"

import EmployerPopUp from "../EmployerPopUp"
import EmployeePopUp from "../EmployeePopUp"
// import Cta from "../Cta"

import * as styles from "./styles.module.less"

import { INLINES } from "@contentful/rich-text-types"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const OccupationalHealth = () => {
  const [showEmployer, setShowEmployer] = useState(false)
  const [showEmployee, setShowEmployee] = useState(false)
  const {
    contentfulServicesPage: {
      occupationalHealthTitle: title = "",
      occupationHealthBody: { raw: body = "" },
      occupationalHealthServices = [],
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulServicesPage(slug: { eq: "services" }) {
          occupationalHealthTitle
          occupationHealthBody {
            raw
          }
          occupationalHealthServices {
            id
            title
            footer {
              raw
            }
            description {
              raw
            }
            icon {
              file {
                url
              }
            }
            relatedServices {
              id
              title
              description {
                raw
              }
              footer {
                raw
              }
            }
          }
        }
      }
    `
  )

  // const handleEmployerClick = (e) => {
  //   e.preventDefault()
  //   setShowEmployer(true)
  // }
  // const handlePatientClick = (e) => {
  //   e.preventDefault()
  //   setShowEmployee(true)
  // }
  const handleCloseClick = (e) => {
    e.preventDefault()
    setShowEmployee(false)
    setShowEmployer(false)
  }

  const mapService = ({
    id,
    title: label,
    icon: {
      file: { url: src },
    },
    description: descriptionRaw,
    footer: footerRaw,
    relatedServices = [],
  }) => {
    const info = descriptionRaw?.raw ? (
      <RichTextToReact
        document={JSON.parse(descriptionRaw?.raw)}
        options={renderOptions}
      />
    ) : null
    const footer = footerRaw?.raw ? (
      <RichTextToReact
        document={JSON.parse(footerRaw?.raw)}
        options={renderOptions}
      />
    ) : null
    const children = relatedServices?.map(mapChildService)

    return (
      <DetailSummary
        key={id}
        label={label}
        src={src}
        info={info}
        footer={footer}
      >
        {children}
      </DetailSummary>
    )
  }

  const mapChildService = ({
    id,
    title: label,
    description: { raw: descriptionDocument = "" },
  }) => {
    const info = (
      <RichTextToReact
        document={JSON.parse(descriptionDocument)}
        options={renderOptions}
      />
    )
    return <ChildDetailSummary key={id} label={label} info={info} />
  }

  return (
    <section className={styles.occupationalHealth}>
      <div className={styles.box}>
        <h1>{title}</h1>
        <div className={styles.body}>
          <RichTextToReact
            document={JSON.parse(body)}
            options={renderOptions}
          />
        </div>
        {/* <p>Log in to view OHSS test results:</p>
        <div className={styles.btnFlex}>
          <Cta text="Employers" link="" onClick={handleEmployerClick} />
          <Cta
            text="Patients"
            link=""
            onClick={handlePatientClick}
            type="bordered"
          />
        </div> */}
        {occupationalHealthServices.map(mapService)}
        <EmployeePopUp display={showEmployee} onClose={handleCloseClick} />
        <EmployerPopUp display={showEmployer} onClose={handleCloseClick} />
      </div>
    </section>
  )
}

export default OccupationalHealth

const DetailSummary = ({ id, src, label, info, footer, children }) => (
  <details key={id} className={styles.parentDetail}>
    <summary className={styles.parentSummary}>
      <div className={styles.summaryContent}>
        <img src={src} className={styles.src} alt="" />
        <div className={styles.label}>{label}</div>
      </div>
    </summary>
    {info}
    {children}
    {footer}
  </details>
)

const ChildDetailSummary = ({ id, label, info }) => (
  <details key={id} className={styles.childDetail}>
    <summary className={styles.childSummary}>
      <div className={styles.nestedLabel}>{label}</div>
    </summary>
    <div className={styles.childPadding}>{info}</div>
  </details>
)
