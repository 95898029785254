import React from "react"

import RichTextToReact from "rich-text-to-react"
import { useStaticQuery, graphql } from "gatsby"

import * as styles from "./styles.module.less"

import { INLINES } from "@contentful/rich-text-types"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const Covid = () => {
  const {
    contentfulServicesPage: {
      covidNotice: {
        title,
        body: { raw: document = "" },
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulServicesPage(slug: { eq: "services" }) {
          covidNotice {
            title
            body {
              raw
            }
          }
        }
      }
    `
  )
  return (
    <section className={styles.covid}>
      <h3>{title}</h3>
      <div className={styles.body}>
        <RichTextToReact
          document={JSON.parse(document)}
          options={renderOptions}
        />
      </div>
    </section>
  )
}

export default Covid
