import React from "react"
import RichTextToReact from "rich-text-to-react"
import { useStaticQuery, graphql } from "gatsby"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const InjuryCare = () => {
  const {
    contentfulServicesPage: {
      injuryCareTitle: title,
      injuryCareBody: { raw: body = "" },
      injuryCareServices = [],
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulServicesPage(slug: { eq: "services" }) {
          injuryCareTitle
          injuryCareBody {
            raw
          }
          injuryCareServices {
            id
            description {
              raw
            }
            title
            icon {
              file {
                url
              }
            }
            relatedServices {
              id
              title
              description {
                raw
              }
            }
            footer {
              raw
            }
          }
        }
      }
    `
  )

  const services = injuryCareServices.map(
    ({
      id,
      title: label,
      icon: {
        file: { url: src },
      },
      description: { raw },
    }) => {
      return {
        id,
        label,
        src,
        info: (
          <RichTextToReact document={JSON.parse(raw)} options={renderOptions} />
        ),
      }
    }
  )

  const renderListItem = ({ id, src, label, info }) => (
    <details key={id}>
      <summary>
        <div className={styles.summaryContent}>
          <img src={src} className={styles.src} alt="" />
          <div className={styles.label}>{label}</div>
        </div>
      </summary>
      {info}
    </details>
  )

  return (
    <section className={styles.injuryCare}>
      <div className={styles.box}>
        <h1>{title}</h1>
        <div className={styles.body}>
          <RichTextToReact
            document={JSON.parse(body)}
            options={renderOptions}
          />
        </div>
        {services.map(renderListItem)}
      </div>
    </section>
  )
}

export default InjuryCare
